import { extractTags } from '@lambda/reebelo/src/tagHelpers';
import { SearchOffersAsYouType } from '@lambda/commons/apis/elasticsearch/types';
import { UrlObject } from 'url';
import { REEBELO_DOMAINS, ReebeloStoreT } from '@lambda/reebelo';
import { tagToSelection } from './collections/catalogue/helpers';
import collections from '@/settings/collections';

export function formatOfferTitle(
  offerTitle: string,
  tags: Record<string, any>,
) {
  const reStrColor = `${
    tags.color?.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') || ''
  }\\s`;
  const reColor = new RegExp(reStrColor, 'g');

  return offerTitle
    .replace(tags.storage || '', '')
    .replace(tags.condition || '', '')
    .replace('Very Good', '')
    .replace(tags.color != null ? reColor : '', tags.color != null ? ' ' : '')
    .replace(/ - /g, ' ')
    .trim();
}

const PSKU_TO_HANDLE: Record<string, string> = Object.entries(collections)
  .map(([handle, { psku }]) => ({ handle, psku }))
  .reduce(
    (acc, { psku, handle }) =>
      psku == null ? acc : { ...acc, [psku]: handle },
    {},
  );

export const formatOfferUri = (
  productHandle: string,
  tags: string[],
  mvno?: string | undefined,
): UrlObject => {
  const { psku } = extractTags(tags);

  if (psku == null)
    return { pathname: '/products/[handle]', query: { handle: productHandle } };
  const collectionHandle = PSKU_TO_HANDLE[psku as any];

  if (collectionHandle == null)
    return { pathname: '/products/[handle]', query: { handle: productHandle } };

  return {
    pathname: '/collections/[handle]',
    query: { handle: collectionHandle, ...tagToSelection(tags), mvno },
  };
};

export const formatOfferUrlString = (
  productHandle: string,
  tags: string[],
  store: ReebeloStoreT,
  mvno?: string,
): string => {
  const { psku } = extractTags(tags);
  const baseUrl = REEBELO_DOMAINS[store];

  if (psku == null) return `${baseUrl}/products/${productHandle}`;
  const collectionHandle = PSKU_TO_HANDLE[psku as any];

  if (collectionHandle == null) return `${baseUrl}/products/${productHandle}`;

  const urlParams = {
    ...tagToSelection(tags),
  };

  if (mvno) urlParams.mvno = mvno;

  return `${baseUrl}/collections/${collectionHandle}?${new URLSearchParams(
    urlParams,
  )}`;
};

export const getOfferUri = (
  searchResult: Pick<
    SearchOffersAsYouType,
    'handle' | 'tags' | 'variantId' | 'productType'
  >,
): UrlObject => {
  if (searchResult.productType?.toLowerCase() === 'gift cards') {
    return {
      pathname: '/products/reebelos-gift-card',
      query: {
        variant: searchResult.variantId,
      },
    };
  }

  const urlObject = formatOfferUri(searchResult.handle, searchResult.tags);

  return urlObject;
};
