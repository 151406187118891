/* eslint-disable import/no-mutable-exports */
import defaultCollections from './default.json';
import USCollections from './US.json';
import { CollectionT } from './types';

let collections = defaultCollections as CollectionT;

if (
  process.env.NEXT_PUBLIC_STORE === 'reebelo-us' ||
  process.env.STORE === 'reebelo-us'
) {
  collections = {
    ...defaultCollections,
    ...(USCollections as Partial<CollectionT>),
  } as CollectionT;
}

export default collections;
